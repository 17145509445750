import {
	useInfiniteQuery,
	useQuery,
	type UseInfiniteQueryOptions,
} from '@tanstack/vue-query';
import { Type, type Static } from '@sinclair/typebox';
import { validatorFactory } from '@laam/lib/validator';
import { useLoganStore } from '~/stores/logan.ts';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';

const productSchema = Type.Object({
	products: Type.Array(
		Type.Object({
			shopify_id: Type.Number(),
			title: Type.String(),
			handle: Type.String(),
			brand: Type.String(),
			partner: Type.String(),
			category: Type.String(),
			status: Type.String(),
			valhalla_score: Type.Number(),
			description: Type.String(),
			total_inventory_quantity: Type.Number(),
			external_product_id: Type.String(),
			price: Type.Number(),
			compare_at_price: Type.Number(),
			tags: Type.Array(Type.String()),
			image: Type.Object({
				shopify_id: Type.Number(),
				alt: Type.String(),
				src: Type.String(),
				width: Type.Number(),
				height: Type.Number(),
			}),
			variants: Type.Array(
				Type.Object({
					shopify_id: Type.Number(),
					price: Type.String(),
					compare_at_price: Type.String(),
					reseller_price: Type.Optional(Type.String()),
					inventory_quantity: Type.Number(),
					weight_unit: Type.String(),
					weight: Type.Number(),
					sku: Type.String(),
					title: Type.String(),
					stock_status: Type.Optional(Type.String()),
				}),
			),
			options: Type.Array(
				Type.Object({
					values: Type.Array(Type.String()),
					name: Type.String(),
				}),
			),
			media: Type.Union([
				Type.Null(),
				Type.Array(
					Type.Object({
						shopify_id: Type.Number(),
						alt: Type.String(),
						src: Type.String(),
						width: Type.Number(),
						height: Type.Number(),
					}),
				),
			]),
			drop_handle: Type.Optional(Type.String()),
			size_chart_id: Type.Optional(Type.Number()),
			size_chart_name: Type.Optional(Type.String()),
			stock_status: Type.Optional(Type.String()),
			attributes: Type.Optional(
				Type.Object({
					'bottom-fabrics': Type.String(),
					'color-type': Type.String(),
					'dupatta-fabrics': Type.String(),
					fabric: Type.String(),
					'number-of-pieces': Type.String(),
					'product-type': Type.String(),
					season: Type.String(),
					'shirt-fabrics': Type.String(),
					'trouser-fabrics': Type.String(),
				}),
			),
			node_l1: Type.Optional(Type.String()),
			node_l2: Type.Optional(Type.String()),
			node_l3: Type.Optional(Type.String()),
			node_l4: Type.Optional(Type.String()),
		}),
	),
	cursor: Type.Object({
		next: Type.Optional(Type.String()),
		prev: Type.Optional(Type.String()),
	}),
	feed_nodes: Type.Optional(
		Type.Array(
			Type.Object({
				id: Type.Number(),
				title: Type.String(),
				parent_id: Type.Number(),
				logo: Type.String(),
				image: Type.String(),
				valhalla_score: Type.Number(),
				product_count: Type.Number(),
				node_level: Type.Number(),
			}),
		),
	),
});

export type Products = Static<typeof productSchema>;
const PAGE_LIMIT = 24;

const productSchemaValidator = validatorFactory<Products>(productSchema);

// fetch products of nodes
export const fetchNodeProducts = async (
	nodeId: string,
	cursor?: string,
	filters?: string,
) => {
	const baseURL = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseURL}/v1/nodes/products?id=${nodeId}&limit=${PAGE_LIMIT}`;
	if (filters) {
		url += `&${filters}`;
	}
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	const data = await response.data;
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', data);
		return scope;
	});
	throw error;
};

// fetch products of brands
export const fetchBrandProducts = async (
	brandHandle: string,
	cursor?: string,
	filters?: string,
) => {
	const baseURL = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseURL}/v1/brands/products?brand_handle=${brandHandle}&limit=${PAGE_LIMIT}`;
	if (filters) {
		url += `&${filters}`;
	}
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			throw new Error('Validation error: ' + e);
		}
	}
	throw new Error('Invalid response from server');
};

// fetch products of drops
export const fetchDropProducts = async (
	dropHandle: string,
	cursor?: string,
) => {
	const baseURL = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseURL}/v1/drops/products?handle=${dropHandle}&limit=${PAGE_LIMIT}`;
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	const data = await response.data;
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', data);
		return scope;
	});
	throw error;
};

// fetch trending products
export const fetchTrendingProducts = async (
	cursor?: string,
	nodeId?: Array<number>,
	filters?: string,
) => {
	let url = `/v1/products?limit=${PAGE_LIMIT}`;
	if (nodeId) {
		url += `&node_id=${nodeId.join(',')}`;
	}
	if (filters) {
		url += `&${filters}`;
	}
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			return productSchemaValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

// fetch query products
export const fetchSearchProducts = async (
	query: string,
	filters: string,
	cursor: string,
	nodeId: string,
) => {
	let url = `/v1/products/search?query=${query}${nodeId ? `&node_id=${nodeId}` : ''}&limit=${PAGE_LIMIT}&${filters}`;
	if (cursor) {
		url += `&cursor=${cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			return productSchemaValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

// fetch trending products
export const fetchCollectionProducts = async (
	handle: string,
	limit: number,
) => {
	let url = '/v1/products';
	if (handle.includes('/brands')) {
		url += `?brand_handle=${handle.split('/brands/')[1]}&limit=${limit}`;
	} else if (handle.includes('/drops')) {
		url += `?drop_handle=${handle.split('/drops/')[1]}&limit=${limit}`;
	} else if (handle.includes('/nodes')) {
		url += `?node_id=${handle.split('/nodes/')[1]}&limit=${limit}`;
	} else {
		url += `?limit=${limit}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);
	if (response.status === 200) {
		try {
			return productSchemaValidator.verify(response.data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);
			Sentry.captureException(error, (scope) => {
				scope.setContext('errors', {
					error: e,
				});
				return scope;
			});
			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');
	Sentry.captureException(error, (scope) => {
		scope.setContext('response', response.data);
		return scope;
	});
	throw error;
};

export const useProductsList = ({
	queryFn,
	queryKey,
	enabledValue,
}: {
	queryKey: UseInfiniteQueryOptions['queryKey'];
	queryFn: UseInfiniteQueryOptions<Products>['queryFn'];
	enabledValue: Ref<boolean>;
}) => {
	const LoganStore = useLoganStore();
	const { isSignedIn } = storeToRefs(LoganStore);
	const {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useInfiniteQuery<Products>({
		queryKey: [queryKey, isSignedIn],
		queryFn: queryFn,
		getNextPageParam: (lastPage) =>
			lastPage.cursor.next == '' ? null : lastPage.cursor.next,
		initialPageParam: '',
		refetchOnWindowFocus: false,
		enabled: enabledValue,
	});

	const { $eventClient } = useNuxtApp();
	watch(isFetchingNextPage, (isFetchingNextPage) => {
		if (isFetchingNextPage)
			$eventClient.sendEvent('pagination', {
				page_number: (data.value?.pages.length || 0) + 1,
				location: window.location.href,
			});
	});

	return {
		data,
		isLoading,
		suspense,
		error,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	};
};

// fetch products of drops
export const fetchEntityProducts = async (
	entity: string,
	params: {
		id?: string;
		handle?: string;
		limit: number;
		cursor?: string;
	},
) => {
	const baseUrl = useRuntimeConfig().public.apiBaseUrl;
	let url = `${baseUrl}/v1/${entity}/products?limit=${params.limit}`;
	if (params.id) {
		url += `&id=${params.id}`;
	} else {
		url += `&handle=${params.handle}`;
	}
	if (params.cursor) {
		url += `&cursor=${params.cursor}`;
	}
	const api = createApiInstance();
	const response = await api.get(url);

	if (response.status === 200) {
		try {
			const data = response.data;
			return productSchemaValidator.verify(data);
		} catch (e) {
			const error = new Error('Failed to verify product data');
			console.error('Failed to verify product data', e);

			throw error;
		}
	}
	const error = new Error('Failed to fetch product data');

	throw error;
};

export const useEntityProducts = (
	entity: string,
	id: Ref<number | undefined>,
	handle: Ref<string | undefined>,
	limit = 10,
) => {
	const enabled = computed(() => !!id.value || !!handle.value);

	const { data, isLoading } = useQuery({
		queryKey: ['entity-products', id, handle],
		queryFn: () =>
			fetchEntityProducts(entity, {
				id: id.value?.toString(),
				handle: handle.value,
				limit: limit,
			}),
		enabled,
	});

	return { data, isLoading };
};
